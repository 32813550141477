.carregamento {
  display: grid;
  grid-template-areas:
    'cabecalho'
    'body'
    'botao';
  grid-template-rows: 60px auto 80px;
}

.carregamento__header {
  grid-area: cabecalho;
}

.carregamento__footer {
  grid-area: botao;
}

.carregamento__body {
  grid-area: body;
  overflow: auto;
  display: grid;
  grid-template-areas:
    'select sort'
    'footer sort';
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto 50px;
}

.carregamento__body-select {
  grid-area: select;
  overflow: auto;
}
.carregamento__body-sort {
  grid-area: sort;
  overflow: auto;
}
.carregamento__body-footer {
  grid-area: footer;
}
