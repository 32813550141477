@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  @apply text-gray-500;
  @apply bg-[#f7f9fc];
  font-family: 'Roboto', sans-serif; /* Use a fonte Roboto */
  font-weight: 400; /* Opção de peso padrão */
}

.fc-event {
  border: none !important; /* Remove a borda padrão dos eventos */
  margin-top: 10px !important;
  width: 90% !important;
}

.fc-col-header-cell.fc-day-today {
  background-color: #c9d4e3 !important;
}

.fc-day-today {
  border: solid 4px #c9d4e3 !important;
}

.fc-scrollgrid-shrink-frame {
  display: none !important;
}

.fc-scrollgrid-sync-table {
  overflow: auto !important;
}

.fc-scrollgrid.fc-scrollgrid-liquid {
  overflow: auto !important;
}

.fc-day-today {
  background-color: #fff8d0 !important;
}
